import {isNil, isNumber, pickBy} from 'lodash'
export const formatUrl = (
    path: string,
    params?: Record<string, string | number | undefined>,
) => {
    const search = new URLSearchParams();
    let url = path

    if (params) {
        for (const key in params) {
            if (isNil(params[key])) continue;
            const exists = url.includes(`:${key}`);

            if (exists) {
                url = url.replace(`:${key}`, String(params[key]));
                search.delete(key);
            } else {
                search.set(key, String(params[key]));
            }
        }
    }
    if (search.toString()) {
        url += `?${search.toString()}`;
    }

    url = url
        .split("/")
        .filter((e) => !e.includes(":"))
        .join("/");

    return url;
};

export const getErrorMessage = (e: any) : string => {
    if ('message' in e) return String(e.message)
    if ('error' in e) return String(e.error)
    if ('errors' in e) {
        if(Array.isArray(e.errors)) return e.errors[0]
        if(typeof e.errors === 'object') return String(Object.values(e.errors)[0])
        return 'generic_error'
    }
    return 'generic_error'
}

export const buildRoute =  (route: string, data: Record<string, any> = {}): string => {
    let merged = {...data}
    let path = Object.keys(merged).reduce((acc, key) => {
        if (acc.match(`:${key}`)) {
            acc = acc.replace(`:${key}`, merged[key])
            delete merged[key]
        }
        return acc
    }, route)

    const search = new URLSearchParams(pickBy(merged))

    return `${path}?${search.toString()}`
}

export const getValueChangePercent = (val?:number|string, prevVal?: number|string) => {
    if(isNil(val) || isNil(prevVal)) return 0
    if(!isNumber(+val) || !isNumber(+prevVal)) return 0
   const res = Math.round((((parseFloat(val.toString()) - parseFloat(prevVal.toString()))) * 100 / parseFloat(val.toString())))
    if(!Number.isFinite(res)) return 0
    return res
}