import useSWR from "swr";
import {apiGetPosInfo, apiGetUserInfo} from "../../api/endpoints";
import {fetcher} from "../../api/api-client";

export interface UserDto {
    user: { email: string, accountId: string, isAdmin: boolean },
    businessName?: string,
    provider: string,
    squareIntegrationActive: boolean
}

export const useCurrentUser = () => {
    const token = localStorage.getItem('token')
    const accountId = new URLSearchParams(window.location.search).get('accountId')

    return useSWR<UserDto>((token || accountId) && apiGetUserInfo, fetcher)
}

export const useUserPosInfo = () => {
    const {data: user} = useCurrentUser()

    return useSWR<{ needToUpdate: boolean }>(user && user.provider && apiGetPosInfo(user.provider))
}