import React, { Component, PropsWithChildren } from "react";
import {GenericError} from "./error-page";
import {attempt, get} from "lodash";

export class ErrorBoundary extends Component<PropsWithChildren> {
    state = { hasError: false, status: 404 };

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const status = get(error, ['response', 'status']) || get(error, 'status') || get(error, 'code') || get(error, 'statusCode') || get(error, 'status_code');
        this.setState({ hasError: true, status: status ?? 500 });
        console.log(error, errorInfo);
        const isChunkLoadError = /loading|chunk/gi.test(error.message);

        if (isChunkLoadError) {
            // Clear caches and refresh the page
            attempt(this.clearCacheAndReload);
            console.log('Chunk load error detected. Clearing caches and reloading the page.');
        }
    }

    onerror = (event: ErrorEvent|PromiseRejectionEvent) => {
        console.log('on error',event)
        if('message' in event && event.message.includes('chunk')){
            this.clearCacheAndReload()
        }
        if('reason' in event && event.reason.message.includes('chunk')){
            this.clearCacheAndReload()
        }

    }

    componentDidMount() {
        window.addEventListener('unhandledrejection', this.onerror);
        window.addEventListener('error', this.onerror);
    }

    componentWillUnmount() {
        window.removeEventListener('unhandledrejection', this.onerror);
        window.removeEventListener('error', this.onerror);
    }

    clearCacheAndReload() {
        const lastClearCache = sessionStorage.getItem('clearCache');
        if(lastClearCache && !isNaN(+lastClearCache) && Date.now() - +lastClearCache < 10e3 ) return;
        // Unregister service workers
        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.getRegistrations().then(function (registrations) {
        //         for (let registration of registrations) {
        //             registration.unregister();
        //         }
        //     });
        // }

        // Clear all caches
        if (window.caches) {
            caches.keys().then(function (cacheNames) {
                return Promise.all(cacheNames.map(function (cacheName) {
                    return caches.delete(cacheName);
                }));
            });
        }
        sessionStorage.setItem('clearCache', Date.now().toString());

        // Reload the page to get the new build
        window.location.reload(); // True forces the browser to reload from the server
    }

    render() {
        if (!this.state.hasError) return this.props.children;
        return <GenericError status={this.state.status} />;
    }
}
