import {ErrorBoundary} from "../entities/eror-page/error-boundary";
import React, {FC, Suspense} from "react";
import {FullPageLoader} from "../entities/page-loader/full-page-loader";
import {Outlet} from "react-router";
import {useBackButton} from "../shared/hooks/useBackButton";

export const TvLayout: FC<React.PropsWithChildren> = ({children}) => {
    useBackButton()
    return <ErrorBoundary>
        <Suspense fallback={<FullPageLoader/>}>
                {children}
                <Outlet/>
        </Suspense>
    </ErrorBoundary>
}