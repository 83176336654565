import * as React from "react";
import {useEffect} from "react";
import {useNavigate} from "react-router";
import {useCurrentUser} from "../entities/user/lib/user.hooks";
import {MainLayout} from "./main.layout";

export const AdminLayout: React.FC<React.PropsWithChildren> = ({children}) => {
    const {data: user} = useCurrentUser()
    const navigate = useNavigate()
    useEffect(() => {
        if (!user) return
        if (!user.user.isAdmin) {
            navigate('/')
        }
    }, [user]);
    return <MainLayout children={children}/>
}