import * as React from 'react';
import {Suspense, useEffect, useState} from 'react';
import * as ReactDOM from 'react-dom/client';
import {SWRConfig} from "swr";
import {fetcher} from "./entities/api/api-client";
import {RouterProvider} from "react-router";
import {appRouter} from "./routes/app.router";
import './styles/index.css'
import './localization/i18n'
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {FullPageLoader} from "./entities/page-loader/full-page-loader";
import {ErrorBoundary} from "./entities/eror-page/error-boundary";
import 'animate.css/animate.min.css'
import {useAppStore} from "./entities/app/lib/app.store";
import {useFullscreen} from "./shared/hooks/useFullscreen";
import {register} from './serviceWorker'

function localStorageProvider() {
    // When initializing, we restore the data from `localStorage` into a map.
    const map = new Map(JSON.parse(sessionStorage.getItem('app-cache') || '[]'))

    // Before unloading the app, we write back all the data into `localStorage`.
    window.addEventListener('beforeunload', () => {
        const appCache = JSON.stringify(Array.from(map.entries()))
        sessionStorage.setItem('app-cache', appCache)
    })

    // We still use the map for write & read for performance.
    return map
}

function App() {
    useFullscreen()
    const {setIsTVMode, isTVMode} = useAppStore()
    const [isAppReady, setIsAppReady] = useState(false)


    const pingApp = async (attemp = 0) => {
        if (isAppReady) return
        if (attemp >= 400) {
            setIsAppReady(true)
            return
        }
        const res = await fetcher('/alive').then(() => true).catch(() => false)

        if (!res) {
            setTimeout(pingApp, 100, attemp + 1)
            return
        }

        setIsAppReady(true)
    }


    useEffect(() => {
        pingApp()
        if (localStorage.getItem('theme') === 'dark') {
            document.body.classList.add('dark')
        } else {
            document.body.classList.remove('dark')
        }

        const params = new URLSearchParams(window.location.search)
        const tvKeywords = ['smart-tv', 'hbbtv', 'netcast', 'appletv', 'googletv', 'chromecast', 'tizen', 'webos', 'roku', 'tv', 'aft'];
        const isLargeScreen = window.screen.width >= 1024 && window.screen.height >= 768;
        const isSmartTV = tvKeywords.some((keyword) => navigator.userAgent.includes(keyword));

        if (params.get('source') && params.get('source') === 'tv') {
            setIsTVMode(true)
        }
        if (isLargeScreen && isSmartTV) {
            setIsTVMode(true)
        }
    }, [])
    useEffect(() => {
        if (isTVMode) {
            document.body.classList.add('tv')
        } else {
            document.body.classList.remove('tv')
        }
    }, [isTVMode])
    return <React.StrictMode>
        <ErrorBoundary>
            {/*<div key={Math.random()} className={'bg-white shadow-lg fixed  bottom-0 right-0 p-4 z-50 flex flex-col'}>*/}
            {/*    <span>width: {window.innerWidth}</span>*/}
            {/*    <span>height: {window.innerHeight}</span>*/}
            {/*    <span>screen: {window.screen.width}x{window.screen.height}</span>*/}
            {/*    <span>pixel-ratio: {window?.devicePixelRatio ?? 'n/a'}</span>*/}
            {/*</div>*/}
            <SWRConfig
                value={{
                    dedupingInterval: 60 * 1000,
                    revalidateOnFocus: true,
                    revalidateOnMount: true,
                    refreshWhenHidden: true,
                    keepPreviousData: true,
                    revalidateIfStale: true,
                    revalidateOnReconnect: true,
                    fetcher: fetcher,
                    // @ts-ignore
                    provider: localStorageProvider,
                }}
            >
                <Suspense fallback={<FullPageLoader/>}>
                    <ToastContainer
                        stacked
                        className={"toasts text-lg"}
                        pauseOnHover
                        draggable
                        // position={}
                        autoClose={5e3}
                    />
                    {
                        !isAppReady ? <FullPageLoader/> : <RouterProvider router={appRouter}/>
                    }

                </Suspense>
            </SWRConfig>
        </ErrorBoundary>
    </React.StrictMode>
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
console.log(process.env.PUBLIC_URL, 'PUBLIC_URL')
root.render(
    <App/>
);

register()