export const apiCreateSquareAccount = '/account/square/register';
export const apiLogin = '/account/login';
export const apiRegister = '/account/register';
export const apiGetUserInfo = '/account/me';

export const apiGetPosInfo =(provider: string) => `/pos/${provider}/integration-info`;

export const apiGetDevices = '/devices/all'

export const apiGetLocations = '/pos/locations'

export const apiActivateSquare = '/account/square/activate'

export const apiClearThing = (id: string) => `/devices/:thing/clear`

export const apiGetOauthURL = `${process.env.REACT_APP_API_URL || 'https://api.pickpad.ai'}/account/oauth/square`

export const apiGetStatistics = '/orders-statistics'

export const apiUpdateStatistics = (id: string) => `/orders-statistics/${id}/update`

export const apiUpdateDevice = (id: string) => `/devices/${id}/update`
export const apiBindDevice = (locationId: string) => `/devices/${locationId}/bind`
export const apiUnbindDevice = (id: string) => `/devices/${id}/unbind`
export const apiTareDevice = (id: string) => `/devices/${id}/tare`
export const apiCalibrateDevice = (id: string) => `/devices/${id}/calibrate`
export const apiClearDevice = (id: string) => `/devices/${id}/clear`
export const apiTestScreenDevice = (id: string) => `/devices/${id}/test-screen`
export const apiDisplayTextDevice = (id: string) => `/devices/${id}/display-text`