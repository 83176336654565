import {useLayoutEffect} from "react";
import {useAppStore} from "../../entities/app/lib/app.store";

export const useFullscreen = () => {
    const {isTVMode} = useAppStore()
    const openFullscreen = async () => {
        const elem = document.documentElement; // The full page
        try {
            if (elem.requestFullscreen) {
                await elem.requestFullscreen()
                //     @ts-ignore
            } else if (elem.mozRequestFullScreen) { // Firefox
                // @ts-ignore
                elem.mozRequestFullScreen()
                //     @ts-ignore
            } else if (elem.webkitRequestFullscreen) { // Chrome, Safari, Opera
                // @ts-ignore
                elem.webkitRequestFullscreen()
                //     @ts-ignore
            } else if (elem.msRequestFullscreen) { // IE/Edge
                // @ts-ignore
                elem.msRequestFullscreen()
            }
        } catch (e) {
        }
    }

    useLayoutEffect(() => {
        if (!isTVMode) return
        document.documentElement.addEventListener('click', openFullscreen)
        openFullscreen()
    }, [isTVMode])
}