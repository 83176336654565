import React, {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Link, useRouteError} from "react-router-dom";
import {AxiosError} from "axios";
import {getErrorMessage} from "../../shared/utils/utils";

export const GenericError: FC<{ status?: number }> = ({status, ...rest}) => {
    const {t} = useTranslation();
    const error = useRouteError();

    const getErrorText = () => {
        if(process.env.NODE_ENV !== 'production') return getErrorMessage(error)
        if(error instanceof Error && 'message' in error && error.message.includes('chunk')){
            return t('Reloading')
        }
        return  t('generic_error')
    };

    const getErrorStatus = () => {
        if (error instanceof AxiosError) {
            return error.status;
        }

        if(error instanceof Error && 'message' in error && error.message.includes('chunk')){
            return 404
        }

        return status || 404;
    };

    const clearCacheAndReload = ()=> {
        const lastClearCache = sessionStorage.getItem('clearCache');
        if(lastClearCache && !isNaN(+lastClearCache) && Date.now() - +lastClearCache < 10e3 ) return;
        // Unregister service workers
        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.getRegistrations().then(function (registrations) {
        //         for (let registration of registrations) {
        //             registration.unregister();
        //         }
        //     });
        // }

        // Clear all caches
        if (window.caches) {
            caches.keys().then(function (cacheNames) {
                return Promise.all(cacheNames.map(function (cacheName) {
                    return caches.delete(cacheName);
                }));
            });
        }
        sessionStorage.setItem('clearCache', Date.now().toString());

        // Reload the page to get the new build
        window.location.reload(); // True forces the browser to reload from the server
    }

    useEffect(() => {
        if( error instanceof Error && 'message' in error && error.message.includes('chunk')){
            clearCacheAndReload()
        }
    }, [error])

    return (
        <div className={"w-screen h-screen flex flex-col items-center justify-center"}>
            <div
                className={"flex mb-5 gap-4 items-center divide-x with-divide-color"}
            >
                <p className={"font-bold text-[90px]/[120px]"}>{getErrorStatus()}</p>
                <p className={"text-2xl pl-4"}>{t("error_text")}</p>
            </div>
            {!!error && (
                <pre
                    className={
                        "text-lg mb-4 max-w-[900px] overflow-auto break-all text-wrap max-h-[400px] bg-gray-300 dark:bg-gray-600 font-mono rounded-lg p-3"
                    }
                >
          {getErrorText()}
        </pre>
            )}

            <Link className={"btn btn-primary btn-wide"} to={"/"}>
                {t("home page")}
            </Link>
        </div>
    );
};
