import {useNavigate, useNavigationType} from "react-router";
import {useEffect} from "react";
import {App as CapacitorApp} from "@capacitor/app";
import {ROUTES} from "../../routes/routes.list";
import {noop} from "lodash";
import {toast} from "react-toastify";
import {useAppStore} from "../../entities/app/lib/app.store";

export const useBackButton = () => {
    const navigate = useNavigate();
    const {isTVMode} = useAppStore()

    const onKeyUp = (event: KeyboardEvent) => {
        if (event.key !== 'BrowserBack') return;
        event.preventDefault();
        goBack()
    }

    const goBack = () => {
        if (ROUTES.SCREENS_LIST === window.location.pathname || window.location.pathname === ROUTES.DISPLAYS_LIST || window.history.length === 1) {
            // Exit the app on the home page
            CapacitorApp.exitApp().catch(noop);
        } else {
            // Navigate back in browser history
            navigate(-1)
        }
    }

    useEffect(() => {
        if (!isTVMode) return;
        document.body.style.setProperty('scroll-behavior', 'smooth');
        document.body.style.setProperty('navigation-policy', 'focus-only');
        document.addEventListener('keydown', onKeyUp);
        window.addEventListener('tvBackButton', goBack)
        document.addEventListener('tvBackButton', goBack)
        try {
            const backButtonListener = CapacitorApp.addListener('backButton', ({canGoBack}) => {
                // Example: Custom back button behavior
                if (ROUTES.SCREENS_LIST === window.location.pathname || window.location.pathname === ROUTES.DISPLAYS_LIST || !canGoBack || window.history.length === 1) {
                    // Exit the app on the home page
                    CapacitorApp.exitApp();
                } else {
                    // Navigate back in browser history
                    navigate(-1)
                }
            }).then(e => e.remove).catch(e => {
                return () => {
                }
            });
            return () => {
                document.removeEventListener('keyup', onKeyUp);
                document.body.style.removeProperty('scroll-behavior');
                document.body.style.removeProperty('navigation-policy');
                window.removeEventListener('tvBackButton', goBack)
                document.removeEventListener('tvBackButton', goBack)
                // Cleanup the listener on component unmount
                backButtonListener.then(e => e())
            };
        } catch (e) {
            console.log(e)
        }
        return () => {
            document.removeEventListener('keyup', onKeyUp);
            document.body.style.removeProperty('scroll-behavior');
            document.body.style.removeProperty('navigation-policy');
            window.removeEventListener('tvBackButton', goBack)
            document.removeEventListener('tvBackButton', goBack)
        }
    }, [isTVMode]);
}