import * as React from "react";
import {Sidebar} from "../entities/sidebar/sidebar";
import {Header} from "../entities/header/header";
import {Outlet} from "react-router";
import {useBackButton} from "../shared/hooks/useBackButton";

export const MainLayout: React.FC<React.PropsWithChildren> = ({children}) => {
    useBackButton()
    return <div>
            <Header/>
        <Sidebar/>
        <div className={'mt-4 lg:mt-10 px-4 pb-8 lg:px-0 pb-8 max-w-[1024px] w-full mx-auto'}>
            {children}
            <Outlet/>
        </div>
    </div>
}