import {create} from "zustand";
import {persist} from 'zustand/middleware';

export interface IAppStore {
    isTVMode: boolean
    lastUsedScreenId?: string
    setIsTVMode: (isTVMode: boolean) => void,
    setLastUsedScreenId: (lastUsedLocationId: string) => void
}

export const useAppStore = create(persist<IAppStore>((set, get) => {
    return {
        isTVMode: false,
        lastUsedScreenId: undefined,
        setIsTVMode: (isTVMode: boolean) => set({isTVMode}),
        setLastUsedScreenId: (lastUsedScreenId: string) => set({lastUsedScreenId}),
    }
}, {
    name: 'appStore',
}));